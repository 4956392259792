<template>
    <v-card>
      <v-toolbar color="#c23b78" dark>
        <v-toolbar-title>
          Editer compte client
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon @click="closeModal">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-row class="mx-2 pt-2">
        <v-col cols="6" style="max-height: 72vh; overflow-y: auto;">
          <v-row>
            <v-col cols="12" sm="12" class="px-0 pb-0">
              <v-card-title class="pl-0 pt-0 subtitle-2">Informations</v-card-title>
            </v-col>
            <v-col cols="6" sm="6" class="py-0">
              <v-autocomplete
                ref="userSelect"
                :items="users"
                :item-text="item => `${item.firstname} ${item.lastname}`"
                label="Développeurs*"
                v-model="usersSelected"
                outlined
                dense
                append-icon="mdi-account"
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.firstname + ' ' + data.item.lastname }}
                </template>
                <template slot="item" slot-scope="data">
                  {{ data.item.firstname + ' ' + data.item.lastname }}
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="6" sm="6" class="py-0">
              <v-menu offset-y :close-on-content-click="false" left>
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="formattedDateTime"
                    label="À diffuser le*"
                    v-on="on"
                    outlined
                    dense
                    append-icon="mdi-calendar"
                  >
                    {{ formattedDateTime }}
                  </v-text-field>
                </template>
                <v-date-picker v-model="requestDate" :locale="$i18n.locale" color="primary"></v-date-picker>
                <v-time-picker v-model="requestTime" format="24hr" scrollable></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" sm="6" class="pt-0">
              <v-select
                v-model="branchSelected"
                :items="branch"
                label="Nom de la branche*"
                outlined
                dense
                append-icon="mdi-source-branch"
                color="primary"
              ></v-select>
            </v-col>
            <v-col cols="6" sm="6" class="pt-0" v-if="diffusionData && diffusionData.repo">
              <!-- possibilité d'indiquer si un fichier d'installation est nécessaire et, si oui, mettre le nom. -->
              <v-checkbox 
                v-if="!diffusionData.repo.isCheck"
                v-model="diffusionData.repo.isCheck"
                label="Fichier d'installation"
                color="primary"
                class="pt-0"
              ></v-checkbox>
              <v-col cols="12" sm="12" class="pa-0 d-flex">
                <v-col cols="2" sm="2" class="pt-0">
                  <v-btn
                    icon
                    v-if="diffusionData.repo.isCheck"
                    color="primary"
                    @click="diffusionData.repo.isCheck = false"
                  > 
                    <v-icon class="mr-2">mdi-close-circle-outline</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="10" sm="10" class="pa-0">
                  <v-text-field
                    v-if="diffusionData.repo.isCheck"
                    v-model="diffusionData.repo.fileInstallation"
                    label="Fichier d'installation"
                    outlined
                    dense
                    append-icon="mdi-file"
                  ></v-text-field>
                </v-col>
              </v-col>
            </v-col>
          </v-row>
          <v-text-field 
            class="pl-0 pt-0 subtitle-2"
            v-model="search"
              label="Rechercher un module, sous module ou lien git"
              outlined
              dense
              append-icon="mdi-magnify"
              @input="searchModule"
            >Modules, sous modules et lien git</v-text-field> 
          <v-card-title class="pl-0 pt-0 subtitle-2">Modules, sous-modules et lien Git</v-card-title>
          <v-progress-circular v-if="loading" indeterminate color="primary" class="center-loader"></v-progress-circular>
          <vue-scroll>
            <span class="pl-2 black--text">
              {{ moduleSelected && moduleSelected.length > 1 ? 'Éléments' : 'Élément' }} sélectionnés : {{ moduleSelected && moduleSelected.length }}
            </span>
            
            <v-treeview v-if="!loading" :items="formatedData" selectable v-model="moduleSelected" selected-color="primary"></v-treeview>
          </vue-scroll>
        </v-col>

        <v-col cols="6">
        <!-- select un package existant -->
        <div v-if="savedConfigs.length > 0">
          <v-card-title class="pl-0 pt-0 subtitle-2">Configuration existante</v-card-title>
          <v-select
            v-model="selectedConfig"
            :items="savedConfigsFormatted"
            label="Configuration existante"
            outlined
            dense
            append-icon="mdi-content-save"
          ></v-select>
        </div>

          <v-card-title class="pa-0 pb-2 subtitle-2">Liste des bases</v-card-title>
          <client-picker :class="!checkedConfig ? 'mb-2' : ''"
            style="max-height: 65vh; overflow-y: auto;"
            :style="savedConfigs.length > 0 ? 'max-height: 60vh; overflow-y: auto;' : ''"
            v-model="baseList"
          ></client-picker>
          <!-- chexbox pour demander de sauvegarder tout les choix cohé dans un tabeau -->
          <v-alert type="info"  dense v-if="!checkedConfig" shaped color="indigo">
            <v-checkbox class="pa-0 ma-0"
              v-model="checkedConfig"
              label="Sauvegarder la configuration ?" 
              dense
              hide-details
            ></v-checkbox> 
          </v-alert>
          <div v-if="checkedConfig"> 
            <v-col cols="10" sm="10" class="d-flex">
                <v-btn
                  icon
                  v-if="checkedConfig"
                  color="red"
                  @click="checkedConfig = false"
                >
                  <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
              <v-text-field
                v-model="configName"
                label="Nom de la configuration"
                outlined
                dense
                append-icon="mdi-content-save"
                hide-details
              ></v-text-field>
            
            </v-col>
          </div>
        </v-col>
      </v-row>
       
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue-darken-1" variant="text" @click="closeModal">
          Annuler
        </v-btn>
        <v-btn color="warning" variant="text" @click="saveAndCloseModal">
          éditer
        </v-btn>
      </v-card-actions>
    </v-card>
</template>
<script>
import moment from 'moment';
import UserService from '@/Services/UserService';
import * as Vuex from "vuex";
import ModuleService from "@/Services/SupportVision/ModuleService";
import DiffusionService from "@/Services/SupportVision/DiffusionService";

export default {
  name: 'Update',
  components: {
    ClientPicker: () => import("@/Components/Commons/Inputs/Client/ClientPicker")
  },
  data() {
    return {
      modalKey: 0, 
      users: [],
      loading: false,
      usersSelected: [],
      moduleSelected: [],
      formatedData: [],
      diffusionData: {},
      requestDate: null,
      requestTime: null,
      baseList: [], 
      branch: ["master", "preprod", "dev"],
      branchSelected: '',
      checkedConfig: false,
      configName: '',
      savedConfigs: [],
      selectedConfig: null,
      search: '',
    }
  },
  filters: {
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD');
    },
  },
  computed: {
    ...Vuex.mapState(['informations']), 
    formattedDateTime() {
      return moment(this.requestDate).format('DD/MM/YYYY') + ' ' + this.requestTime;
    },
    selectedDiffusionId() {
      return this.$store.state.selectedDiffusionId;
    },
    savedConfigsFormatted() {
      return this.savedConfigs.map((config) => {
        return {
          text: config.config.package,
          value: config._id
        }
      });
    },
  },
  watch: {
    selectedDiffusionId() {
      this.loadData();
    }
  },
  methods: {
    closeModal() {
      this.$emit('close-update');
    },
    resetForm() {
      
    },
    searchModule() {
      
    },
    async saveAndCloseModal() {
      const data = {
        user: this.usersSelected,
        module: this.moduleSelected,
        baseList: this.baseList,
        requestDate: this.requestDate + ' ' + this.requestTime,
        branch: this.branchSelected,
        devMail: this.informations.emailAddress,
      };
      
      await DiffusionService.updateDiffusion(this.selectedDiffusionId, data);
      this.$emit('reload')
      this.$emit('close-update');
    },
    async loadData() {
      this.loading = true;
      const users = await UserService.getUsers().then((res) => {
        this.users = res;
      });
      // recuperer les configurations sauvegardées
      const res = await DiffusionService.getConfigs();
      res && res.length > 0 ? this.savedConfigs = res : this.savedConfigs = [];

      this.diffusionData = await DiffusionService.getDiffusionById(this.selectedDiffusionId);
      this.usersSelected = this.diffusionData.repo.user;
      this.moduleSelected = this.diffusionData.repo.module;
      this.baseList = this.diffusionData.repo.baseList;
      this.requestDate = moment(this.diffusionData.repo.requestDate).format('YYYY-MM-DD');
      this.requestTime = moment(this.diffusionData.repo.requestDate).format('HH:mm');
      this.branchSelected = this.diffusionData.repo.branch;

      const dataModule = await ModuleService.getModules();
      const uniqueIds = new Set(); // Utiliser un ensemble pour stocker les ids uniques déjà rencontrés
      this.formatedData = dataModule.data.map((module) => {
        const moduleId = this.getUniqueKey(module._id, uniqueIds);
        uniqueIds.add(moduleId); // Ajouter l'id au set des ids uniques

        return {
          id: moduleId,
          name: module.module.module + ' :',
          children: module.sous_module.map((sousModule) => {
            const sousModuleId = this.getUniqueKey(sousModule.idSousModule, uniqueIds);
            uniqueIds.add(sousModuleId); // Ajouter l'id au set des ids uniques

            return {
              id: sousModuleId,
              name: sousModule.sous_module + ' - V' + sousModule.version,
              children: [
                {
                  id: sousModule.sousModuleGit,
                  name: sousModule.sousModuleGit,
                }
              ]
            }
          }) 
        }
      });
      this.loading = false; 
    },
    getUniqueKey(id, uniqueSet) {
      let uniqueId = id;
      while (uniqueSet.has(uniqueId)) {
        uniqueId = `${id}_${Math.random().toString(36).substr(2, 9)}`;
      }
      return uniqueId;
    }
  },
  async mounted() {
    this.loadData();
  },
};
</script>

<style scoped>
.center-loader {
  position: absolute;
  top: 50%;
  left: 25%;
}
</style>
